<template lang="pug">
  .set-for-future-days
    SetForFutureDays(
      :references="shops"
      :references-loading="shopsLoading"
      :inventory-fields="['shop_inventory_limit', 'sale_stop']"
    )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    name: "AutoSetInventoryShopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  export default {
    components: {
      SetForFutureDays: () => import("@/components/InventoryManagement/SetForFutureDays")
    },

    mixins: [shopMatchingMixin],

    mounted() {
      this.fetchShops({ pagination: { _disabled: true }, matched_only: true })
    }
  }
</script>

<style lang="sass" scoped></style>

import { isEmpty, isUndefined, transform, forEach, reduce, isObject, isEqual } from "lodash-es"

export const invalidInventories = (initialInventories, changedInventories) => {
  if (isEmpty(initialInventories)) return {}

  return transform(
    inventoriesWithMappedKeys(initialInventories),
    (obj, initialInventory, date) => {
      const changedInventory = getChangedInventory(changedInventories[date], initialInventory)
      const isInventoryChanged = isChanged(initialInventory, changedInventory)

      const availableInventory = shopAvailableValue(changedInventory)

      const isAvailableInventoryNegative = availableInventory < 0

      const isOtaSaleStopChanged = isOtasSaleStoppedChanged(initialInventory, changedInventory)

      const isInvalid =
        (isAvailableInventoryNegative && !isOtaSaleStopChanged) ||
        (isInventoryChanged && isOtaSaleStopChanged && isAvailableInventoryNegative)

      obj[date] = isInvalid
      return obj
    },
    {}
  )
}

export const shopAvailable = (isChanged, changedInventory) => {
  return isChanged || changedInventory.id ? shopAvailableValue(changedInventory) : 0
}

const shopAvailableValue = changedInventory => {
  const { shop_inventory_limit, blocked_inventory } = changedInventory
  const value =
    Number(shop_inventory_limit) -
    Number(blocked_inventory) +
    Math.min(Number(sharedAvailableValue(changedInventory)), 0)

  return value
}

export const sharedAvailable = (isChanged, changedInventory) => {
  return isChanged || changedInventory.id ? Math.max(sharedAvailableValue(changedInventory), 0) : 0
}

const sharedAvailableValue = changedInventory => {
  return Number(changedInventory.shared_inventory_limit) - Number(bookedInventoryTotal(changedInventory))
}

export const bookedInventoryTotal = changedInventory => {
  return Object.values(changedInventory.booked_inventories_by_ota).reduce((value, acc) => {
    return value === null ? acc : value + acc
  }, null)
}

export const availableInventory = (isChanged, changedInventory, sharedAvailable, shopAvailable) => {
  return isChanged || changedInventory.id ? sharedAvailable + shopAvailable : 0
}

const inventoriesWithMappedKeys = inventories => {
  return transform(
    inventories,
    (obj, { inventory_by_days }, carClassId) => {
      forEach(inventory_by_days, (inventory, date) => {
        obj[`${carClassId},${date}`] = inventory
      })

      return obj
    },
    {}
  )
}

export const isChanged = (initialInventory, changedInventory) => {
  return (
    isInventoryChanged("shop_inventory_limit", initialInventory, changedInventory) ||
    isInventoryChanged("shared_inventory_limit", initialInventory, changedInventory) ||
    isInventoryChanged("blocked_inventory", initialInventory, changedInventory)
  )
}

export const isInventoryChanged = (key, initialInventory, changedInventory) => {
  return initialInventory[key] !== changedInventory[key]
}

const getChangedInventory = (changedInventory, initialInventory) => {
  if (isUndefined(changedInventory)) {
    return initialInventory
  } else {
    return { ...initialInventory, id: changedInventory.id, ...changedInventory?.inventory }
  }
}

export const cleanedInventoryData = inventoryData => {
  return reduce(
    inventoryData,
    (object, value, key) => {
      if (key.includes("_inventory") || key == "export_is_notified") {
        object[key] = value || 0
      } else if (isObject(value)) {
        object[key] = value
      }
      return object
    },
    {}
  )
}

/* check if OTA sale_stop list from backend is different from updated on frontend */
const isOtasSaleStoppedChanged = (initialInventory, changedInventory) => {
  const oldOtaIds = [...initialInventory.sale_stop_ota_ids]
  const saleStoppedOtaIds = [...changedInventory.sale_stop_ota_ids]

  return !isEqual(oldOtaIds.sort(), saleStoppedOtaIds.sort())
}

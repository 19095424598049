<template lang="pug">
  ImportInventoryModal(
    :is-import-disabled="isImportDisabled"
    :import-inventory-available-loading="importInventoryAvailableLoading"
    @start-import="importInventory"
    @close="$emit('close')"
  )
    template(v-slot:import-filters)
      .import-filters
        .shops
          .title {{ $t("inventory_management.inventory_import.shop") }}
          AppDropdown(
            :autofocus="false"
            multiple
            batch-select
            allow-empty
            searchable
            checkbox
            only-body
            :loading="shopsLoading"
            :value="shops"
            :items="shopsItems"
            @select="setShops"
          )
        .car-classes
          .title {{ $t("inventory_management.inventory_import.car_class") }}
          AppDropdown(
            :autofocus="false"
            multiple
            batch-select
            allow-empty
            searchable
            checkbox
            only-body
            :loading="carClassesLoading"
            :value="carClasses"
            :items="carClassesItems"
            @select="setCarClasses"
          )
</template>

<script>
  // // mixins
  import modalCommonMixin from "@/components/InventoryManagement/ImportInventoryModal/modalCommonMixin"

  // misc
  import { bus } from "@/config"
  import { get, intersection, isEmpty } from "lodash-es"

  export default {
    mixins: [modalCommonMixin],

    props: {
      inProgressReferenceItemIds: {
        type: Array,
        default: () => new Array()
      }
    },

    data() {
      return {
        shops: [],
        carClasses: []
      }
    },

    computed: {
      isSelectedShopsExportInProgress({ shops, inProgressReferenceItemIds }) {
        return !isEmpty(intersection(get(shops, "id"), inProgressReferenceItemIds))
      },

      isImportDisabled({ isSelectedShopsExportInProgress, importAvailable, isInvalidSelection }) {
        return isSelectedShopsExportInProgress || !importAvailable || isInvalidSelection
      },

      isInvalidSelection({ shops, carClasses }) {
        return isEmpty(shops) || isEmpty(carClasses)
      },

      importFilters({ shops, carClasses }) {
        return { shops, carClasses }
      }
    },

    methods: {
      setShops(shops) {
        this.shops = shops
      },

      setCarClasses(carClasses) {
        this.carClasses = carClasses
      },

      importInventory() {
        bus.emit("import-inventory", { ...this.importFilters })

        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/inventory-management.sass"

  +import-inventory-modal-filters
</style>

<template lang="pug">
  .long-period-setting
    WarningAlert
    .filters
      .dates
        DatepickerFilter(
          :value="filters.date"
          :min-date="minDate"
          :max-date="maxDate()"
          @change="handleChangeDate"
        )
        WeekDayFilter(
          :weekdays="filters.weekdays"
          :holiday-options="filters.holidayOptions"
          @input:weekdays="filters.weekdays = $event"
          @input:holiday-options="filters.holidayOptions = $event"
        )
      Dropdowns.dropdowns(
        :shop="filters.shop"
        :car-classes="filters.carClasses"
        :shops-items="shops"
        :car-classes-items="carClasses"
        :shops-loading="shopsLoading"
        :car-classes-loading="carClassesLoading"
        @select-shop="handleSelectShop"
        @select-car-classes="handleSelectCarClasses"
      )
    SettingList(
      :settings="settings"
      :is-shared-inventory-disabled="isSharedInventoryDisabled"
      :is-nicolas="isNicolasEnabled"
      @change="handleChangeSettings"
    )
    .update-to-ota
      AppButton(
        :disabled="isEmpty(settings)"
        title="inventory_management.inventory_long_period.save_changes"
        @click="saveChanges"
      )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import carClassMatchingModule from "@/config/store/matching/car_class"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { bus } from "@/config"
  import { isEmpty, reduce } from "lodash-es"
  import { SERIALIZER_VIEW_FILTERS } from "@/config/constants"
  import { startOfCurrentDateInTimeZone } from "@/helpers/dates"
  import { toObjectBy } from "@/helpers/common"
  import { mapGetters } from "vuex"

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    name: "LongPeriodShopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "LongPeriodCarClassMatching",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const minDate = startOfCurrentDateInTimeZone()

  const DEFAULT_SETTING_OBJECT = {
    shop_inventory_limit: null,
    shared_inventory_limit: null
  }

  export default {
    components: {
      WarningAlert: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/WarningAlert"),
      DatepickerFilter: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/DatepickerFilter"),
      WeekDayFilter: () => import("@/components/InventoryManagement/SetInventoryForLongPeriod/WeekDayFilter"),
      Dropdowns: () => import("./Dropdowns"),
      SettingList: () => import("./SettingList"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    mixins: [shopMatchingMixin, carClassMatchingMixin],

    mounted() {
      this.filters = { ...this.filters, date: [minDate, this.maxDate()] }

      this.fetchShops({ pagination: { _disabled: true }, matched_only: true, synced_only: true })
    },

    data() {
      return {
        minDate,
        filters: {
          shop: {},
          carClasses: [],
          date: [],
          weekdays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true
          },
          holidayOptions: {
            holiday: true,
            tomorrow_holiday: true
          }
        },
        settings: {}
      }
    },

    computed: {
      ...mapGetters(["maxDate", "isNicolasEnabled", "otasWithoutNicolas"]),

      isSharedInventoryDisabled({ filters }) {
        return isEmpty(filters.shop.inventory_group)
      },

      groupedCarClasses({ carClasses }) {
        return toObjectBy("id", carClasses)
      },

      otas({ otasWithoutNicolas }) {
        return otasWithoutNicolas
      },

      defaultSettingsObject({ otas }) {
        return {
          ...DEFAULT_SETTING_OBJECT,
          sale_settings: reduce(
            otas,
            (obj, { id }) => {
              obj[id] = {
                add_sale_stop: false,
                delete_sale_stop: false
              }

              return obj
            },
            {}
          )
        }
      }
    },

    methods: {
      isEmpty,

      handleChangeDate(date) {
        this.filters.date = date
      },

      handleSelectShop(shop) {
        this.filters = { ...this.filters, shop, carClasses: [] }
        this.actualizeSettings()

        this.fetchCarClasses({
          pagination: { _disabled: true },
          shop_id: shop.id,
          synced_only: true,
          sources_active_only: true,
          serializer_view: SERIALIZER_VIEW_FILTERS
        })
      },

      handleSelectCarClasses(carClasses) {
        this.filters.carClasses = carClasses
        this.actualizeSettings()
      },

      actualizeSettings() {
        let index = 0

        const updatedSettings = reduce(
          this.filters.carClasses,
          (obj, { id, order }) => {
            index = this.isNicolasEnabled ? index + 1 : order

            const setting = this.settings[index] || this.defaultSettingsObject
            obj[index] = { ...setting, carClass: this.groupedCarClasses[id] }

            return obj
          },
          {}
        )

        this.settings = updatedSettings
      },

      handleChangeSettings(settings) {
        this.settings = settings
      },

      saveChanges() {
        bus.emit("set-long-period-inventory", {
          settings: this.settings,
          ...this.filters
        })

        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .long-period-setting
    width: 800px
    margin-bottom: -$modal-padding

    .filters
      margin-bottom: 25px

      .dates
        align-items: flex-start
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        height: 85px

      .dropdowns
        height: 70px

    .update-to-ota
      background: $default-white
      bottom: -$modal-padding
      display: flex
      justify-content: flex-end
      padding-top: 15px
      position: sticky
      width: 100%

      ::v-deep
        .app-button
          background: $default-purple
          color: $default-white
          font-size: 0.8rem
          text-transform: uppercase
</style>

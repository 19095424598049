import { isNil, get, filter, keys, isEmpty, isEqual, uniq } from "lodash-es"
import { getEnabledOtaIds } from "@/helpers/inventory"

export const inventoryObject = (
  { shop_inventory_limit, shared_inventory_limit, sale_settings },
  { ota_data, inventory_by_days },
  data
) => {
  const oldInventoryObject = get(inventory_by_days, data, {})
  const newInventoryObject = {}

  if (!isNil(shop_inventory_limit) && shop_inventory_limit !== oldInventoryObject.shop_inventory_limit) {
    newInventoryObject.shop_inventory_limit = Number(shop_inventory_limit)
  }

  if (!isNil(shared_inventory_limit) && shared_inventory_limit !== oldInventoryObject.shared_inventory_limit) {
    newInventoryObject.shared_inventory_limit = Number(shared_inventory_limit)
  }

  const saleStopOtaIds = saleStopByOta(ota_data, inventory_by_days, data, sale_settings)
  const deleteSaleStops = saleStopsBySettings(sale_settings, ota_data, false)

  if (!isEqual(saleStopOtaIds.sort(), oldInventoryObject.sale_stop_ota_ids.sort())) {
    const oldSaleStops = oldInventoryObject.sale_stop_ota_ids.filter(id => !deleteSaleStops.includes(id))

    newInventoryObject.sale_stop_ota_ids = uniq([...saleStopOtaIds, ...oldSaleStops])
  }

  return isEmpty(newInventoryObject) ? null : { ...oldInventoryObject, ...newInventoryObject }
}

const saleStopByOta = (otaData, inventoryData, data, saleSettings) => {
  const addSaleStops = saleStopsBySettings(saleSettings, otaData)
  const deleteSaleStops = saleStopsBySettings(saleSettings, otaData, false)

  if (isEmpty(addSaleStops) && isEmpty(deleteSaleStops)) return get(inventoryData, `${data}.sale_stop_ota_ids`) || []

  return addSaleStops
}

const saleStopsBySettings = (saleSettings, otaData, sale = true) => {
  const enabledOtas = getEnabledOtaIds(otaData)
  const field = sale ? "add_sale_stop" : "delete_sale_stop"

  return filter(keys(saleSettings), otaId => saleSettings[otaId][field] && enabledOtas.includes(+otaId)).map(
    data => +data
  )
}

import { forEach, isNil } from "lodash-es"
import { getCheckWeekdayFunctions, getAllowedFormattedDates } from "./dates"
import { inventoryObject } from "./inventory"

export default ({ settings, weekdays, date, carClasses, holidayOptions }, inventories, isNicolas = false) => {
  const changedInventories = {}
  const checkWeekdayFunctions = getCheckWeekdayFunctions(weekdays)
  const allowedDates = getAllowedFormattedDates(date, checkWeekdayFunctions, holidayOptions)

  let index = 0
  forEach(carClasses, ({ id: carClassId, order }) => {
    index = isNicolas ? index + 1 : order

    forEach(allowedDates, formattedDate => {
      const carClassSettings = settings[index]
      const carClassInventories = inventories[carClassId]
      const inventoryChange = inventoryObject(carClassSettings, carClassInventories, formattedDate)
      if (!isNil(inventoryChange)) {
        changedInventories[[carClassId, formattedDate]] = {
          car_class_id: carClassId,
          date: formattedDate,
          inventory: inventoryChange
        }
      }
    })
  })

  return changedInventories
}

import { transform, every } from "lodash-es"

/**
 *  Returns array of OTA ids for disabled or enabled OTAs
 *
 *  @return {array} Filtered OTA ids collection
 */
const getOtaIds = ({ otaData, disabled }) => {
  return transform(
    // otaData = [
    //   {
    //     ota_id: 1,
    //     inactive_ota: false
    //     inactive_source_car_class: false
    //     inactive_source_shop: false
    //     not_matched_car_class: false
    //     not_matched_shop: false
    //   },
    //   {
    //     ota_id: 2,
    //     inactive_ota: true
    //     inactive_source_car_class: false
    //     inactive_source_shop: false
    //     not_matched_car_class: false
    //     not_matched_shop: false
    //   },
    //   ...
    // ]
    otaData,
    (array, { ota_id, ...disabledOptions }) => {
      // check that all properties have one value - which is into `disabled` argument
      if (every(disabledOptions, value => value === disabled)) array.push(ota_id)

      return array
    },
    []
  )
}

export const getEnabledOtaIds = otaData => getOtaIds({ otaData, disabled: false })
